define("boekdrukken/services/stripe", ["exports", "boekdrukken/util/load-script", "boekdrukken/util/computed"], function (_exports, _loadScript, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    key: (0, _computed.config)(function (config) {
      var key = config.stripe.key;
      (false && !(!!key) && Ember.assert("config/environment boekdrukken.stripe.key is required", !!key));
      return key;
    }),
    load: function load() {
      var _this = this;

      var promise = this._load;

      if (!promise) {
        promise = (0, _loadScript.default)('https://js.stripe.com/acacia/stripe.js', function (window) {
          return window.Stripe(_this.key);
        });
        this._load = promise;
      }

      return promise;
    },
    createPayment: function createPayment() {
      var model = this.store.models.create('stripe/payment', {
        stripe: this
      });
      model.load();
      return model;
    }
  });

  _exports.default = _default;
});